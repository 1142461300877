import React, {Component} from 'react';

import "./separator.scss";

class SeparatorComponent extends Component {
    render() {
        return (
            <div className="separator" />
        );
    }
}

export default SeparatorComponent;
